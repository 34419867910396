import * as React from 'react';
// importを追記
import { useStaticQuery, graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
// componentsを追記
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import nl2br from '../../components/extend';

const Posts = ({ data, location }) => {
const post = data.contentfulBlogPost;
return (
<Layout>
  {/* meta要素 */}
  {/* (NULLの場合はgatsby-config.jsのsiteMetadataを使用する) */}
  <SEO
    title={post.title + " | TUREDUREGUSA, Modern translation"}
    description=""
    keyword=""
    site_name=""
    url=""
    og_title=""
    og_type=""
    og_image=""
    tw_cardType=""
    tw_userName=""
  />
  {/* html要素 */}
  <div className="g-container">
    <main className="g-pageMain" role="main">
      <div className="l-container">
        <div className="u-mt-x5">
          <p className="c-leadText"><strong>TUREDUREGUSA, Modern translation</strong></p>
          <h1 className="c-headingLv1">{post.title}</h1>
          <div className="u-mt-x5 u-box u-box-shadow">
            <h2 className="c-headingLv2">Original</h2>
            <p className="c-text u-fz-18">{nl2br(post.body.body)}</p>
          </div>
          <div className="u-mt-x5 u-box u-box-strong u-box-shadow">
            <h2 className="c-headingLv2">Modern translation</h2>
            <p className="c-text u-fz-18">{nl2br(post.description.description)}</p>
          </div>
          {post.heroImage && (
          <div className="u-mt-x5 u-box u-box-strong">
            <div className="l-grid l-grid-center">
              <div className="l-grid_item l-grid_item-6">
                <div className="c-img"><img src={post.heroImage.file.url} alt="" /></div>
              </div>
            </div>
          </div>
          )}
          <div className="u-mt-x5">
            <p className="c-text"><Link to={`/post/`+post.slug}>Japanese translation</Link></p>
            <p className="c-text"><Link to="/en/">Back to home</Link></p>
          </div>
          {console.log(post)}
        </div>
        <div className="u-mt-x5 u-ta-right">
          <p className="c-pageTop" data-js-localscroll>
            <a href="#top">
              <img
                className="i-label i-label-left"
                src="/assets/img/icons/ic_tri_up.svg"
                alt=""
              />
              Page top
            </a>
          </p>
        </div>
      </div>
      {/* /.l-container */}
    </main>
    {/* /.g-pageMain */}
  </div>
  {/* /.g-container */}
</Layout>
)
};

export default Posts;

export const pageQuery = graphql`
query($slug: String) {
  contentfulBlogPost(
      slug: {eq: $slug},
      node_locale: {eq: "en-US"}
    ) {
    title
    slug
    publishDate(fromNow: false, locale: "", formatString: "")
    node_locale
    id
    body {
      body
    }
    description {
      description
    }
    heroImage {
      id
      file {
        url
      }
    }
    author {
      id
      name
      twitter
      title
      company
      email
      facebook
      github
      childContentfulPersonShortBioTextNode {
        shortBio
      }
    }
  }
}
`;
